<template>
  <div style="padding: 10px; height: 100%">
    <header style="text-align: right; margin-bottom: 10px">
      <Button type="primary"
              @click="addNF">新建虚拟护栏</Button>
    </header>
    <Table :loading="tableLoading"
           :columns="NFcolumns"
           :data="NFlist">
      <!-- @on-row-click='inDetail' -->
    </Table>
    <Page :total="total"
          v-if="total > 10"
          show-total
          :page-size="limit"
          class="page"
          :current="parseInt(page)"
          size="small"
          show-sizer
          :page-size-opts="[10, 15, 20]"
          @on-page-size-change="pageSizeChange"
          @on-change="pageChange" />
    <!-- 弹窗 -->
    <Modal v-model="model"
           fullscreen
           @on-cancel="cancel"
           footer-hide>
      <p slot="header"
         style="text-align: center; font-weight: bolder; font-size: 0.18rem">
        {{ add ? "新建虚拟护栏" : "编辑虚拟护栏" }}
      </p>
      <div class="model">
        <div class="NFset">
          <h2>虚拟护栏设置</h2>
          <p>
            <img src="../../assets/img/Icon/drone_speed.png" />最大飞行速度 M/S
          </p>
          <Slider v-model="speed"
                  show-input
                  :min="1"
                  :max="15"></Slider>
          <p>
            <img src="../../assets/img/Icon/drone_hight.png" />最大飞行高度 M
          </p>
          <Slider v-model="high"
                  show-input
                  :min="-200"
                  :max="500"></Slider>
          <!-- <p>
            <img
              src="../../assets/img/statistics/newroute_finish.png"
            />适用范围
          </p>
          <Select v-model="Range" multiple style="width: 3rem">
            <Option
              v-for="item in RangeList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select> -->
          <p>虚拟护栏命名</p>
          <Input v-model.trim="NFname"
                 clearable
                 style="width: 3rem" />
          <Button type="primary"
                  long
                  @click="saveNF"
                  class="btn">保存</Button>
        </div>
        <div style="width: 80%; height: 100%; position: relative">
          <div id="map_NF"></div>
          <div class="searchBox"
               v-if="$online">
            <input id="searchMap_1"
                   autocomplete="off"
                   placeholder="地点搜索" />
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-model="renameModel"
           title="虚拟护栏重命名"
           :loading="true"
           @on-ok="remaneOK">
      <Input type="text"
             v-model.trim="renameValue"
             placeholder="请输入虚拟护栏名称" />
    </Modal>
    <Modal v-model="delModel"
           :loading="true"
           @on-ok="delOK"
           width="300">
      <p style="text-align: center; font-size: 16px; margin: 40px 0 20px">
        删除该虚拟护栏？
      </p>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
import {
  GetNotFlyArea,
  GetTeamNotFlyList,
  ModifyNotFlyName,
  DelNotFly,
  AddNotFlyArea,
} from '@/utils/javaApi.js';
export default {
  name: 'noFlyZone',

  data() {
    return {
      polyline: null,
      userInfo: {},
      teamID: -1,
      model: false,
      modal_loading: false,
      map: '',
      NFname: '',
      NFpath: [], // 获取的虚拟护栏，全国统一
      polygon: {}, // 虚拟护栏覆盖物
      newNF: [], // 绘制的虚拟护栏
      polygonEditor: '', // 多边形编辑工具
      mouseTool: '', // 鼠标绘制工具
      speed: 1,
      high: 0,
      add: false, // 新增模式
      RangeList: [
        {
          value: 1,
          label: '自由飞行',
        },
        {
          value: 2,
          label: '航线飞行',
        },
        {
          value: 3,
          label: '建图航拍',
        },
      ],
      Range: [1, 2, 3],
      page: 1,
      showPage: false,
      tableLoading: false,
      total: 10,
      limit: 10,
      NFcolumns: [
        {
          title: '名称',
          key: 'address',
          align: 'center',
        },
        // {
        //   title: "适用范围",
        //   key: "role",
        //   align: "center",
        //   render: (h, params) => {
        //     let roles = "";
        //     if (params.row.role.length === 0) {
        //       roles = "未定义适用范围";
        //     } else {
        //       if (params.row.role.indexOf("1") != -1) {
        //         roles += " 自由飞行 ";
        //       }
        //       if (params.row.role.indexOf("2") != -1) {
        //         roles += " 航线飞行 ";
        //       }
        //       if (params.row.role.indexOf("3") != -1) {
        //         roles += " 建图航拍 ";
        //       }
        //     }
        //     return h("span", roles);
        //   },
        // },
        {
          title: '是否启用',
          key: 'is_forbiden',
          align: 'center',
          render: (h, params) => {
            return h('i-switch', {
              props: {
                size: 'large',
                value: params.row.is_forbiden === 1,
              },
              on: {
                'on-change': (e) => {
                  this.forbiden(e, params.row.id);
                },
              },
              scopedSlots: {
                open: () => h('span', '启用'),
                close: () => h('span', '禁用'),
              },
            });
          },
        },
        {
          title: '操作',
          key: 'action',
          width: 320,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'info',
                  },
                  style: {
                    marginRight: '0.15rem',
                  },
                  on: {
                    click: () => {
                      this.rename(params.row.id, params.row.address);
                    },
                  },
                },
                '重命名'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                  },
                  style: {
                    marginRight: '0.15rem',
                  },
                  on: {
                    click: () => {
                      this.editNF(params.row);
                    },
                  },
                },
                '编辑'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                  },
                  style: {
                    background: 'rgb(245, 108, 108)',
                    color: '#fff',
                    border: 'rgb(245, 108, 108)',
                  },
                  on: {
                    click: () => {
                      this.del(params.row.id);
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ],
      NFlist: [],
      renameModel: false,
      renameValue: '', // 重命名输入框预设值
      checkID: '', // 选中的虚拟护栏id
      delModel: false,
      NFdata: '', // 编辑中的虚拟护栏路径数据
      provinceCode: '', // 虚拟护栏行政编码
    };
  },

  methods: {
    inDetail(data) {
      this.add = false;
      this.checkID = data.id;
      this.NFdata = data.local_data;
      this.high = data.high_max;
      this.speed = data.speed_max;
      this.NFname = data.address;
      this.model = true;
      this.Range = data.role.split(',').map(Number);
      this.initMap();
    },
    // 获取虚拟护栏列表
    initNFlist() {
      this.showPage = false;
      this.tableLoading = true;
      this.$post(GetTeamNotFlyList(), {
        page: this.page,
        team_id: this.teamID,
        limit: this.limit,
      }).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total;
          if (this.total > 10) {
            setTimeout(() => {
              this.showPage = true;
            }, 100);
          }
          this.NFlist = res.data.data;
          this.tableLoading = false;
        }
      });
    },
    // 翻页
    pageChange(p) {
      this.page = p;
      sessionStorage.setItem('page', p);
      this.initNFlist();
    },
    pageSizeChange(l) {
      this.limit = l;
      this.pageChange(1);
    },
    // 启禁用虚拟护栏
    forbiden(e, id) {
      let f = e ? 1 : 0;
      this.$post(ModifyNotFlyName(), {
        client: 'web',
        is_forbiden: f,
        notfly_id: id,
      }).then((res) => {
        this.$Message.info(res.msg_customer);
        if (res.code === 1) {
          this.checkID = '';
        }
      });
    },
    // 重命名弹窗
    rename(id, name) {
      this.checkID = id;
      this.renameModel = true;
      this.renameValue = name;
    },
    // 确认重命名
    remaneOK() {
      this.$post(ModifyNotFlyName(), {
        client: 'web',
        address: this.renameValue,
        notfly_id: this.checkID,
      }).then((res) => {
        this.$Message.info(res.msg_customer);
        if (res.code === 1) {
          this.renameModel = false;
          this.initNFlist();
          this.checkID = '';
        }
      });
    },
    // 删除虚拟护栏
    del(id) {
      this.checkID = id;
      this.delModel = true;
    },
    delOK() {
      this.$post(DelNotFly(), {
        notfly_id: this.checkID,
      }).then((res) => {
        this.$Message.info(res.msg_customer);
        if (res.code === 1) {
          this.delModel = false;
          this.initNFlist();
          this.checkID = '';
        }
      });
    },
    /**
     * 新建编辑虚拟护栏
     */
    // 新增虚拟护栏
    addNF() {
      this.add = true;
      this.NFdata = '';
      this.model = true;
      this.initMap();
    },
    // 编辑虚拟护栏
    editNF(data) {
      this.add = false;
      this.checkID = data.id;
      this.NFdata = data.local_data;
      this.high = data.high_max;
      this.speed = data.speed_max;
      this.NFname = data.address;
      this.model = true;
      this.Range = data.role.split(',').map(Number);
      this.initMap();
    },
    // 初始化地图
    initMap() {
      let that = this;
      let layer = [];
      if (this.$online) {
        // layer = [new AMap.TileLayer.Satellite()];
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }
      this.map = new AMap.Map('map_NF', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 18, //初始化地图层级
        center: this.$Center, //初始化地图中心点
        layers: layer,
      });
      if (this.$online) {
        let autoComplete = new AMap.Autocomplete({ input: 'searchMap_1' });
        AMap.event.addListener(autoComplete, 'select', function (e) {
          that.map.setCenter([e.poi.location.lng, e.poi.location.lat]);
        });
      }
      // 添加虚拟护栏
      this.initNFzone();

      // 绘制虚拟护栏--新建
      if (this.add) {
        this.initDraw();
      } else {
        this.getDraw('', this.NFdata);
        // 设置地图中心点为虚拟护栏中心
        // let lat =
        //   (Math.max(...this.NFdata.map((x) => x.lat)) +
        //     Math.min(...this.NFdata.map((x) => x.lat))) /
        //   2;
        // let lng =
        //   (Math.max(...this.NFdata.map((x) => x.lng)) +
        //     Math.min(...this.NFdata.map((x) => x.lng))) /
        //   2;
        // this.map.setCenter(new AMap.LngLat(lng, lat));
        // this.map.setZoom(13);
      }
    },

    // 初始化虚拟护栏
    initNFzone() {
      let that = this;
      this.$post(GetNotFlyArea(), {
        showTeam: 0,
        team_id: this.userInfo.team_id,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = [];
            if (this.checkID !== element.id) {
              element.local_data.forEach((ele) => {
                path.push(new AMap.LngLat(ele.lng, ele.lat));
              });
            }
            this.NFpath.push(path);
            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            });
            this.map.add(polygon);
            if (!this.add) {
              this.map.setFitView([this.polyline]);
            }
          });
        }
      });
    },

    // 绘制虚拟护栏
    initDraw() {
      // 新增
      this.mouseTool = new AMap.MouseTool(this.map); // 注册鼠标绘制工具
      //监听draw事件可获取画好的覆盖物
      this.mouseTool.on('draw', this.getDraw);
      this.mouseTool.polygon({
        fillColor: '#c41a16',
        strokeColor: '#ff0000',
        fillOpacity: 0.3,
        // extData: { type: 5 },
      });
    },
    // 绘制物
    getDraw(e, d) {
      let that = this;
      if (d) {
        // 编辑
        let p = [];
        d.forEach((ele) => {
          p.push(new AMap.LngLat(ele.lng, ele.lat));
        });
        let polyline = new AMap.Polygon({
          path: p,
          fillColor: '#c41a16',
          strokeColor: '#ff0000',
          fillOpacity: 0.3,
        });
        this.polyline = polyline;
        this.map.add(polyline);
        // this.map.setFitView([polyline]);
        this.map.plugin('AMap.PolyEditor', function () {
          that.polygonEditor = new AMap.PolyEditor(that.map, polyline);
          that.polygonEditor.open();
          that.newNF = []; // 初始化新虚拟护栏
        });
      } else {
        // 新增
        this.mouseTool.close();
        this.map.plugin('AMap.PolyEditor', function () {
          that.polygonEditor = new AMap.PolyEditor(that.map, e.obj);
          that.polygonEditor.open();
          that.newNF = []; // 初始化新虚拟护栏
          console.log(that.polygonEditor);
        });
      }

      // 编辑监听
      this.polygonEditor.on('end', function (event) {
        let ll = [];
        let path = event.target.getPath();
        if (that.$online) {
          ll.push(path[0].R); // 修改地图api
          ll.push(path[0].Q);
          path.forEach((ele) => {
            that.newNF.push({ lat: ele.lat, lng: ele.lng });
          });
          that.getAddsByPosition(ll);
        } else {
          // 离线版没有查询,直接sumbit()
          path.forEach((ele) => {
            that.newNF.push({ lat: ele.lat, lng: ele.lng });
          });
          that.sumbit();
        }
      });
    },

    getAddsByPosition(mylnglat) {
      let that = this;
      this.map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          //city: '010',
        });
        geocoder.getAddress(mylnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.provinceCode = result.regeocode.addressComponent.adcode;
            console.log(that.provinceCode);
            // that.newTaskAreaName = result.regeocode.formattedAddress;
            // result为对应的地理位置详细信息
            that.sumbit();
          }
        });
      });
    },

    // 保存虚拟护栏
    saveNF() {
      if (this.NFname == '') {
        this.$Message.error('虚拟护栏名称不能为空');
        return;
      } else {
        // 判断是否绘制，进入编辑模式后绘制完成
        if (this.polygonEditor) {
          // 关闭编辑功能，并获取编辑后的对象
          this.polygonEditor.close();
          // 避免异步操作导致无法获取provinceCode
          // this.sumbit();
        } else {
          this.$Message.warning('未绘制虚拟护栏');
        }
      }
    },
    // 更新虚拟护栏 --新增|修改|启用
    sumbit() {
      // 提交内容：虚拟护栏路径，名称，备注
      if (this.add) {
        this.$post(AddNotFlyArea(), {
          local_data: JSON.stringify(this.newNF),
          address: this.NFname,
          speed_max: this.speed,
          high_max: this.high,
          role: this.Range.toString(),
          province_code: this.provinceCode,
        }).then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.model = false;
            this.add = false;
            this.high = 0;
            this.checkID = '';
            this.speed = 0;
            this.newNF = [];
            this.polygon = {};
            this.NFname = '';
            this.Range = [1, 2, 3];
            this.polygonEditor = '';
            this.initNFlist();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        this.$post(ModifyNotFlyName(), {
          local_data: JSON.stringify(this.newNF),
          address: this.NFname,
          speed_max: this.speed,
          high_max: this.high,
          notfly_id: this.checkID,
          role: this.Range.toString(),
          client: 'web',
          province_code: this.provinceCode,
        }).then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.model = false;
            this.add = false;
            this.high = 0;
            this.checkID = '';
            this.speed = 0;
            this.newNF = [];
            this.polygon = {};
            this.NFname = '';
            this.Range = [1, 2, 3];
            this.polygonEditor = '';
            this.initNFlist();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },

    // 关闭弹窗，清空绘制数据
    cancel() {
      if (this.add) {
        this.mouseTool.close();
        this.mouseTool = '';
      }
      this.map.remove(this.polygon);
      this.add = false;
      this.high = 0;
      this.checkID = '';
      this.speed = 0;
      this.newNF = [];
      this.polygon = {};
      this.NFname = '';
      this.Range = [1, 2, 3];
      this.polygonEditor = '';
      this.map.destroy();
    },
  },

  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page');
    }

    if (
      sessionStorage.getItem('team_id') === null ||
      sessionStorage.getItem('team_id') == -1 ||
      this.userInfo.permission == 0
    ) {
      this.teamID = this.userInfo.team_id;
      //   this.teamID = -1;
      this.initNFlist();
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
      this.initNFlist();
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      if (value == -1) {
        this.teamID = this.userInfo.team_id;
      } else {
        this.teamID = value;
      }
      this.pageChange(1);
    });
    this.$_bus.$off('addNFzone').$on('addNFzone', this.addNF);
  },
};
</script> 

<style lang="scss" scoped>
.searchBox {
  position: absolute;
  z-index: 900;
  top: 0.3rem;
  width: 100%;
  height: 0.5rem;
  #searchMap_1 {
    height: 0.4rem;
    border-radius: 0.5rem;
    border: none;
    padding-left: 0.55rem;
    width: 5.5rem;
    margin: 0 auto;
    display: block;
    box-shadow: 0 0 4px #000;
  }
}
// header {
//   position: relative;
//   display: flex;
//   margin: 0 0.3rem 0.1rem;
//   h2 {
//     font-weight: bold;
//     font-size: 0.2rem;
//     margin-right: 0.3rem;
//   }
// }
.model {
  display: flex;
  width: 100%;
  height: 100%;
  .NFset {
    width: 20%;
    height: 100%;
    position: relative;
    color: $white;
    font-size: 0.14rem;
    h2 {
      font-weight: bold;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    padding-right: 0.16rem;
    p {
      margin: 0.1rem 0 0.05rem;
      img {
        display: inline-block;
        margin-right: 0.13rem;
      }
    }
    .btn {
      position: absolute;
      bottom: 0.2rem;
      left: 0;
      width: 3.6rem;
    }
  }
  #map_NF {
    width: 100%;
    height: 100%;
  }
}
.jfqgl {
  color: $white;
}
.page {
  text-align: right;
  margin-top: 0.4rem;
}
/deep/.ivu-modal-fullscreen .ivu-modal-body {
  //   background-color: $xf_hui1;
  color: $white;
}
/deep/.ivu-modal-fullscreen .ivu-modal-header {
  //   background-color: $xf_hui1;
  color: $white;
}
/deep/ .ivu-modal-header p {
  color: $white;
}
/deep/ .ivu-icon-ios-close:hover {
  color: $white;
}
</style>